const domain =`https://api-uat.madfu.com.sa/admin/`;
const domainNew =`https://api-uat.madfu.com.sa/api/admin/`;

// const imgUrl = `https://madfu-development.s3.ap-south-1.amazonaws.com/data/merchantsetting/logo/`;
const mainUrl = `https://api-uat.madfu.com.sa/`;
const basicAuth = 'Basic Ym5wbHJvb3R1c2VybmFtZTpMOG5HcTdlZEhNSlFnb1pUWkluZXN5NlptZWRNdFpOU0hRN2M3ZDRxYm96cnI=';
const countryRestrictions = {country: ["PS","SA", "EG", "IN", "NG", "US"]};

export const environment = {
  production: false,
  publicUrl: domain,
  countryRestrictions,
  // imgUrl,
  mainUrl,
  basicAuth,
  environmentName: 'dev',
  newPublicUrl:domainNew
};
